import template from './template.html';

class LoadingIconController {
  constructor() {
  }
}

let loadingIcon = {
  bindings: {
  },
  controller: LoadingIconController,
  templateUrl: template
};

export default loadingIcon
